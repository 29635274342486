
const scrollHeader = () => {
  document.addEventListener('scroll', function(e) {
    const headerScroll = document.querySelector('.header');
    const headerLogo = document.querySelector('.header__logo-image')

    if(window.innerWidth > 767){
      if(window.scrollY > 0) {
        if (headerScroll) headerScroll.classList.add('scrolled')
        if (headerLogo) headerLogo.classList.add('scrolled')

      } else {
        if (headerScroll) headerScroll.classList.remove('scrolled')
        if (headerLogo ) headerLogo.classList.remove('scrolled')
      }

  }
});
new WOW(
  {
    mobile: false,
  }
).init();
}

export default scrollHeader