/**
* Foreach polyfill for browsers
*/

const topFunction = () => { 
  document.body.scrollTop = 0; // For Safari 
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera 
}

const flickityBanner = () => {
  const elem = document.querySelector('.banner__container');
  //@ts-ignore
  const flkty = new Flickity( elem, {
    // options
    fade: true,
    cellAlign: 'left',
    contain: true,
    prevNextButtons: false,
    autoPlay: 3000,
    pauseAutoPlayOnHover: false
  });
  const carouselTotal = document.querySelector('.js-carousel-total');

  if (carouselTotal) carouselTotal.textContent = flkty.slides.length;

  // const updateIndexNum = () => {
  //   const slideNumber = flkty.selectedIndex + 1
  //   carouselIndex[index].textContent = '0' + slideNumber
    // // carouselTotal[index].textContent = '0' + flkty.slides.length 
    // console.log(flkty.slides.length)
  // }

  // flkty.on('select', updateIndexNum)
  // updateIndexNum()
  flkty.on( 'change', function (selectedIndex) {
    const carouselIndex = document.querySelector(".js-carousel-index");
    if (carouselIndex) carouselIndex.textContent = selectedIndex + 1
  } );

  const buttonTop = document.querySelector(".footer__arrow-button");

  if (buttonTop) buttonTop.addEventListener('click',topFunction);
}

export default flickityBanner