
const sideMenu = () => {
  var buttonMenu = document.querySelector('.header__button');

  buttonMenu.addEventListener('click', function() {
      var sideMenu = document.querySelector('.header__side-menu');
      
      if(sideMenu.classList.value.includes('active')) {
          document.querySelector('.header__side-menu').classList.remove('active');
      } else document.querySelector('.header__side-menu').classList.add('active');
  });
});
  
}

export default sideMenu