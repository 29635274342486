import forEachPolyfill from './components/forEachPolyfill';
import flickityBanner from './components/flickityBanner';
import scrollHeader from './components/scrollHeader';
import sideMenu from './components/sideMenu';

document.addEventListener(
  'DOMContentLoaded',
  () => {
    forEachPolyfill()
    flickityBanner()
    scrollHeader()
    sideMenu()
  },
  false
)

